<template>
  <v-card class="text-sm h-full">
    <project-list-add-new
      v-model="isAddNewProjectSidebarActive"
      :blankProjectData="blankProjectData"
      @refetch-data="fetchProjects"
    ></project-list-add-new>
    
    <v-navigation-drawer
      v-model="isEventHandlerDrawerActive"
      right
      touchless
      app
      temporary
      width="500"
      v-click-outside="closeDrawer"
    >
      <deal-event-handler-drawer-content
        :deal="deal"
        :stages="stagesOptions"
        :clear-deal-data="clearDealData"
        @remove-deal="removeDeal"
        @add-deal="addDeal"
        @update-deal="updateDeal"
        @close-drawer="closeDrawer"
        @create-project="createProject"
      ></deal-event-handler-drawer-content>
    </v-navigation-drawer>
    <div>
         <v-card>
            <v-row class="px-2 ma-0">
                <v-col cols="12"
                    md="4">
                    <v-autocomplete
                    v-model="office"
                    placeholder="Pipeline"
                    label="Pipeline"
                    :loading="officeLoading"
                    :items="officeOptions"
                    :search-input.sync="officeSearch"
                    item-text="hs_label"
                    item-value="id"
                    outlined
                    clearable
                    dense
                    hide-details
                    @change="officeSearch=''"
                ></v-autocomplete>
                </v-col>
                <v-col cols="12"
                    md="4">
                    <v-autocomplete
                    v-model="ownerFilter"
                    placeholder="Filter by owners"
                    label="Deal Owners"
                    :loading="usersLoading"
                    :items="hubspotUsersOptions"
                    :search-input.sync="ownerSearch"
                    item-text="name"
                    item-value="hubspot_owner_id"
                    outlined
                    small-chips
                    deletable-chips
                    clearable
                    dense
                    hide-details
                    multiple
                    @change="ownerSearch=''"
                    @input=" updateFilter('owners', $event)"
                >
                <template v-slot:chip="{ item }">
                    <div class="d-flex align-center">
                        <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                        </div>
                </template>
                    <template v-slot:item="{ item }">
                        <div class="d-flex align-center">
                        <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                        <span class="text-sm">{{ item.name }}</span>
                        </div>
                    </template>
                </v-autocomplete>
                </v-col>
                <v-col cols="12"
                    md="4">
                    <div class="text-right pr-3 mb-3">
                        <v-btn
                            dark
                            color="primary"
                            class="mr-3"
                            href="https://bvagroup.sharepoint.com/:f:/s/PRSIV-GrandCentral/ErV42M0utApEs1Ur5kBfT4AB5GJj0lslWhmWhP0T5iZ2rg?e=lpTqkr"
                            target="_blank">
                            Pitch Decks
                        </v-btn>
                        <v-btn
                        dark
                        color="warning"
                        @click="createDeal()"
                        >
                        Create Deal
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-container class="mx-0">
                <v-row>
                    <v-col cols="12">
                        <div class="flex overflow-x-auto pb-2">
                
                        <v-card
                            v-for="stage in stages"
                            :key="stage.id"
                            class="bg-gray-100 rounded-lg px-2 py-3 column-width rounded mr-2 "
                            min-width="250"
                            outlined
                        >
                        <v-card-title
                            class="pa-0 mx-0 mb-4 text-xs font-weight-bold"
                        >
                            {{stage.label}}
                            <v-spacer></v-spacer>
                            
                            {{stage.total}}
                        </v-card-title>
                        <v-card 
                            class="overflow-y-auto hubspot-stage"
                            
                            :style="`box-shadow: none !important; `"
                        >
                        <v-card-text
                            class="pa-0 ma-0"
                        >
                            <draggable 
                            :id="stage.id"
                            :list="stage.deals"
                            :animation="200"
                            ghost-class="ghost-card"
                            group="deals"
                            @add="onAdd"
                            @change="changeStage($event, stage)"
                            >
                            <deal-card
                                v-for="(deal) in stage.deals"
                                :key="deal.id"
                                :deal="deal"
                                v-on:click.native="dealHandleEventClick(deal)"
                                class="mt-1 cursor-pointer"
                            ></deal-card>
                            
                        </draggable>
                        </v-card-text>
                        <v-card-text class="text-center text-xs-center mb-1 pa-0"
                        v-if="stage.deals.length < parseInt(stage.total)">
                            <v-btn 
                                class="ma-2 white--text"
                                color="secondary"
                                small
                                @click="loadDeals(stage.id)"
                            >
                            Show more
                            </v-btn>
                        </v-card-text>
                    </v-card>
                        </v-card>
                <!-- <div class="flex ">
                    <div
                    v-for="stage in stages"
                    :key="stage.id"
                    class="bg-gray-100 rounded-lg px-3 py-3 column-width rounded mr-2 "
                    > 
                    
                    <v-row class="stage-column-header pb-2">
                        <v-col sm="10">
                        <div class="text-gray-700 font-semibold font-sans tracking-wide text-xs">{{stage.label}}</div>
                        </v-col>
                        <v-col sm="2">
                        <div class="text-gray-700 font-semibold font-sans tracking-wide text-xs">
                            {{stage.total}}
                        </div>
                        </v-col>
                    </v-row>
                    <div class="overflow-y-auto hubspot-screen fullscreen-wrapper">
                        <draggable 
                            :id="stage.id"
                            :list="stage.deals"
                            :animation="200"
                            ghost-class="ghost-card"
                            group="deals"
                            @add="onAdd"
                            @change="changeStage($event, stage)"
                            >
                            
                            
                            <deal-card
                                v-for="(deal) in stage.deals"
                                :key="deal.id"
                                :deal="deal"
                                v-on:click.native="dealHandleEventClick(deal)"
                                class="mt-3 cursor-pointer"
                            ></deal-card>
                            
                        </draggable>
                        <div class="text-center mb-3 text-xs-center">
                            <v-btn v-if="stage.deals.length < parseInt(stage.total)"
                                class="ma-2 white--text"
                                color="secondary"
                                small
                                @click="loadDeals(stage.id)"
                            >
                            Show more
                            </v-btn>
                        </div>
                    </div>
                    </div>
                </div> -->
            </div>
            </v-col>
            </v-row>
            </v-container>  
        </v-card>
    </div>
  </v-card>
</template>

<script>
import store from '@/store'
import router from '@/router'
import DealEventHandlerDrawerContent from './DealEventHandlerDrawerContent.vue'
import { onMounted, onUnmounted, ref, getCurrentInstance, watch, computed } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import useHubspotList from './useHubspotList'
import ProjectListAddNew from '@/views/apps/project/project-list/ProjectListAddNew.vue'

import hubspotStoreModule from './hubspotStoreModule'
import DealCard from "./DealCard.vue";
import Draggable from "vuedraggable";
import { stringToHslColor } from '@core/utils'
import { avatarText } from '@core/utils/filter'

export default ({
    components: {
        DealCard,
        Draggable,
        DealEventHandlerDrawerContent,
        ProjectListAddNew
    },
    setup() {
        const vm = getCurrentInstance().proxy
        const HUBSPOT_APP_STORE_MODULE_NAME = 'app-hubspot'

        // Register module
        if (!store.hasModule(HUBSPOT_APP_STORE_MODULE_NAME)) store.registerModule(HUBSPOT_APP_STORE_MODULE_NAME, hubspotStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(HUBSPOT_APP_STORE_MODULE_NAME)) store.unregisterModule(HUBSPOT_APP_STORE_MODULE_NAME)
        })
        
        const {
            officeLoading,
            officeOptions,
            officeSearch,
            office,
            stages,
            ownerFilter,
            ownerSearch,
            fetchOffices,
            fetchDeals,
            stagesOptions,
            convertDealObjToHub,
            convertHubToDealObj,
            removeDealFromStage,
            addDealFromStage,
            getUserDetail,
            fetchUsers,
            usersLoading,
            usersOptions,
            loadFiltersFromLS,
            updateFilter

        } = useHubspotList()

        
        const isAddNewProjectSidebarActive = ref(false)
        const blankProjectData = ref({})

        const isEventHandlerDrawerActive = ref(false)
        const dealWindowActive = ref(false)
        const previousStage = ref('')

        const newIndex = ref(0)
        const oldIndex = ref(0)

        const initDealMoveIndexes = () => {
            newIndex.value = 0
            oldIndex.value = 0
        }

        const blankDeal = {
            id: '',
            createdAt: null,
            updatedAt: null,
            archived: '',
            properties: {
                dealname: '',
                closedate: null,
                createdate: null,
                fieldwork_start_date: null,
                fieldwork_end_date: null,
                // hs_all_assigned_business_unit_ids: null,
                surveys_type: '',
                hubspot_owner_id: null,
                prevstage: null,
            },
            associations:{
                companies:{
                    results:[]
                },
                contacts:{
                    results:[]
                }
            }
        }
        const deal = ref(JSON.parse(JSON.stringify(blankDeal)))
        const clearDealData = () => {
            deal.value = JSON.parse(JSON.stringify(blankDeal))
        }

        const createDeal = () => {
            clearDealData()
            deal.value.properties.pipeline = officeOptions.value.filter(pipeline => pipeline.id === office.value).map(pipeline => pipeline.hs_pipeline)[0]
            isEventHandlerDrawerActive.value = true
        }

        const dealHandleEventClick = (dealObj, stage=null) => {
            store.commit('app/LOADING', true)
            store
            .dispatch('app-hubspot/fetchDeal', dealObj)
            .then(response => {
                const { data } = response
                const associations = data.associations
                if (associations) {
                    if (associations.companies.results) {
                        data.associations.companies.results = associations.companies.results.filter(item => item.type === 'deal_to_company')
                    }
                    if (associations.contacts.results) {
                         data.associations.contacts.results = associations.contacts.results.filter(item => item.type === 'deal_to_contact')
                    }
                }

                if (stage) data.properties.dealstage = stage
                if (previousStage.value) data.properties.prevstage = previousStage.value
                previousStage.value = ''

                deal.value = data

                isEventHandlerDrawerActive.value = true
                // remove loading state
                store.commit('app/LOADING', false)
            })
            .catch(error => {
                console.log(error)
            })
        }

        const addDeal = async (deal) => {
            const dealLocal = convertDealObjToHub(deal)
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            store
            .dispatch('app-hubspot/addDeal', dealLocal)
            .then(response => {
                const { data } = response
                addDealFromStage(data, data.properties.dealstage)
                clearDealData()
                closeDrawer()
                // isEventHandlerDrawerActive.value = false
                // remove loading state
            })
            .catch(error => {
                console.log(error)
            })
        }
        
        const updateDeal = async (deal, prevStage=null) => {
            const dealLocal = convertDealObjToHub(deal)
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            store
            .dispatch('app-hubspot/updateDeal', dealLocal)
            .then(response => {
                const { data } = response
                const responseDeal = convertHubToDealObj(data)
                const dealIdx = stages.value[prevStage].deals.findIndex(deal => deal.id === responseDeal.id)
                if (prevStage == responseDeal.properties.dealstage){
                    stages.value[responseDeal.properties.dealstage].deals[dealIdx] = responseDeal;
                } else {
                    removeDealFromStage(dealIdx, prevStage)
                    addDealFromStage(responseDeal, responseDeal.properties.dealstage)
                }
                clearDealData()
                closeDrawer()
                // isEventHandlerDrawerActive.value = false
                // remove loading state
            })
            .catch(error => {
                console.log(error)
            })
            
        }

        const removeDeal = async (dealLocal) => {
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            store
            .dispatch('app-hubspot/deleteDeal', { id: dealLocal.id })
            .then(response => {
                const { data } = response
                if (response.status === 204) {
                    const dealIdx = stages.value[dealLocal.properties.dealstage].deals.findIndex(deal => deal.id === dealLocal.id)
                    removeDealFromStage(dealIdx, dealLocal.properties.dealstage)
                    clearDealData()
                    closeDrawer()
                    // isEventHandlerDrawerActive.value = false
                    console.log('delete')
                }
                // remove loading state
            })
            .catch(error => {
                console.log(error)
            })
        }

        const loadDeals = async (stage) => {
            const token = await vm.$msal.getTokenPopup(foundryRequest)
            const params = ref({
                pipeline: officeOptions.value.filter(pipeline => pipeline.id === office.value).map(pipeline => pipeline.hs_pipeline)[0],
                dealstage: stage
            })
            if(stages.value[stage].paging){
                params.value['after'] = stages.value[stage].paging.next.after;
            }
            store
            .dispatch('app-hubspot/fetchDeals', params.value)
            .then(response => {
                const { data } = response
                for (var idx in data[0].deals){
                    let currentDeal = data[0].deals[idx]
                    if (stages.value[stage].deals.findIndex(deal => deal.id === currentDeal.id) == -1){
                        currentDeal.user = getUserDetail(currentDeal.properties.hubspot_owner_id)
                        stages.value[stage].deals.push(currentDeal)
                    }
                }
                stages.value[stage].paging = data[0].paging
            })
            .catch(error => {
                console.log(error)
            })
        }

        const changeStage = (item, stage) => {
            const { added, removed } = item
            if (added){
                stage.total += 1
                dealHandleEventClick(added.element, stage.id)
            }

            if (removed) {
                stage.total -= 1
                previousStage.value = stage.id
            }
            
        }

        const closeDrawer = (prevStage = null) => {
            if((dealWindowActive.value && !isEventHandlerDrawerActive.value) || typeof prevStage === 'string') {
                if (deal.value.id){
                    const currentStage = deal.value.properties.dealstage
                    if (typeof prevStage === 'object') prevStage = deal.value.properties.prevstage
                    if (typeof prevStage === 'string') {
                        removeDealFromStage(newIndex.value, currentStage)
                        addDealFromStage(deal.value, prevStage, oldIndex.value)
                        initDealMoveIndexes()
                    }
                    clearDealData()
                }
                dealWindowActive.value = false
                isEventHandlerDrawerActive.value = false
            }
            if (!prevStage && dealWindowActive.value && isEventHandlerDrawerActive.value) {
                dealWindowActive.value = false
                isEventHandlerDrawerActive.value = false
            }
        }

        const onAdd = (evt) => {
            newIndex.value = evt.newIndex
            oldIndex.value = evt.oldIndex
        }

        const createProject = (project) => {
            isAddNewProjectSidebarActive.value = true
            isEventHandlerDrawerActive.value = false
            blankProjectData.value = project
            // router.push({
            //     name: 'project-list',
            //     params:{
            //         blankProjectData: project,
            //         addNewProject: true,
            //     }
            // })
        }

        const fetchProjects = () => {

            router.push({
                name: 'project-list'
            })
        }

        const hubspotUsersOptions = computed(() => {
            return usersOptions.value.filter( user => user.hubspot_owner_id !== '' && user.office == office.value)
        })

        watch(
            () => isEventHandlerDrawerActive.value,
            (oldValue, newValue) => {
                dealWindowActive.value = true
            }
        )


        onMounted (() => {
            loadFiltersFromLS()
            fetchOffices()
            fetchDeals()
            fetchUsers()
        })

        return {
            deal,
            clearDealData,
            officeLoading,
            officeOptions,
            officeSearch,
            office,
            stages,
            ownerFilter,
            ownerSearch,
            updateFilter,
            hubspotUsersOptions,
            usersLoading,
            usersOptions,
            stagesOptions,
            dealHandleEventClick,
            isEventHandlerDrawerActive,
            addDeal,
            updateDeal,
            removeDeal,
            closeDrawer,

            isAddNewProjectSidebarActive,
            blankProjectData,
            fetchProjects,

            createDeal,
            changeStage,
            onAdd,
            loadDeals,
            createProject,

            stringToHslColor,
            avatarText,
        }

    },
})
</script>

<style lang="scss">
    .v-main {
        // If AppBar & Footer is present
        &[style='padding: 64px 0px 56px 260px;'],
        &[style='padding: 64px 0px 56px 68px;'], // collapsed
        &[style='padding: 64px 260px 56px 0px;'], // RTL
        &[style='padding: 64px 68px 56px 0px;'], // RTL Collapsed
        &[style='padding: 64px 0px 56px;'] {
        .hubspot-stage {
            height: calc((var(--vh, 1vh) * 100) - (1.5rem * 2) - 300px);
        }
        }
    }

</style>