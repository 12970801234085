<template>
  <v-card
    min-width="200"
    outlined>

    <v-card-title class="pt-2 pr-2 pb-0 pl-5 mx-0 text-xs">
      <span class="font-weight-bold">
        {{deal.properties.dealname}}
      </span>
      
      <v-spacer></v-spacer>
      <v-tooltip top v-if="deal.user">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on">
            <img
              class="w-2 h-2 rounded-full ml-2"
              :src="deal.user.avatar"
              alt="Avatar"
              v-if="deal.user.avatar"
            >
            <v-avatar
              :color="deal.user.color"
              size="25"
              v-else
            >
              <span class="white--text text-xs">{{deal.user.initial}}</span>
            </v-avatar>
          </div>
        </template>
        <span>{{deal.user.name}}</span>
      </v-tooltip>
    </v-card-title>
    <v-card-text class="pb-2">
      <div class=" justify-between">
          <span class="text-xs text-gray-600">Amount: </span>
          <span class="text-xs text-gray-600">{{Number(deal.properties.amount).toLocaleString('fr-FR', { style: 'currency', currency: deal.properties.deal_currency_code})}}</span>
      </div>
      <div class="justify-between items-center" v-if="deal.properties.closedate">
          <span class="text-xs text-gray-600">Closed Date: </span>
        <span class="text-xs text-gray-600">{{formatDate(deal.properties.closedate)}}</span>
      </div>
      <div v-if="deal.properties.business__initiative" >
        <v-divider></v-divider>
        <div class="flex justify-between items-center mt-2">
          <deal-badge :color="badge.color">{{badge.label}}</deal-badge>
        </div>
      </div>
    </v-card-text>

    <!-- <div class="bg-white shadow rounded px-3 pt-3 pb-5 border border-white">
      <div class="flex justify-between">
        <p class="text-gray-700 font-semibold font-sans tracking-wide text-sm">
          {{deal.properties.dealname}}
        </p>
        <div v-if="deal.user">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on">
                <img
                  class="w-2 h-2 rounded-full ml-2"
                  :src="deal.user.avatar"
                  alt="Avatar"
                  v-if="deal.user.avatar"
                >
                <v-avatar
                  :color="deal.user.color"
                  size="25"
                  v-else
                >
                  <span class="white--text text-xs">{{deal.user.initial}}</span>
                </v-avatar>
              </div>
            </template>
            <span>{{deal.user.name}}</span>
          </v-tooltip>
          
        </div>
      </div>
      <div class=" justify-between">
          <span class="text-xs text-gray-600">Amount: </span>
          <span class="text-xs text-gray-600">{{Number(deal.properties.amount).toLocaleString('fr-FR', { style: 'currency', currency: deal.properties.deal_currency_code})}}</span>
      </div>
      <div class="justify-between items-center" v-if="deal.properties.closedate">
          <span class="text-xs text-gray-600">Closed Date: </span>
        <span class="text-xs text-gray-600">{{formatDate(deal.properties.closedate)}}</span>
      </div>
      <div v-if="deal.properties.business__initiative" >
        <v-divider></v-divider>
        <div class="flex justify-between items-center mt-2">
          <deal-badge :color="badge.color">{{badge.label}}</deal-badge>
        </div>
      </div>
    </div> -->
  </v-card>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import DealBadge from "./DealBadge.vue";
import { formatDate } from '@core/utils/filter'
import store from '@/store'


export default {
  components: {
    DealBadge
  },
  props: {
    deal: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props) {
    const dealColorMappings = computed( () =>{
        return store.getters['app-hubspot/dealColorMappings']
    })
    const badge = computed(() => {
      return dealColorMappings.value[props.deal.properties.business__initiative] || dealColorMappings.value.default;
    })

    return {
        badge,
        dealColorMappings,
        formatDate
    }
  
  } 
};
</script>
