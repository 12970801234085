import store from '@/store'
import axios from '@axios'
import { transformRequestOptions } from '@core/utils'

export default {
  namespaced: true,
  state: {
    dealColorMappings: {
        "New_Product_without_product_experience": {color: "purple", label: "New Product without Product Experience"},
        "New_Product_with_product_experience":  {color: "indigo", label: "New Product with Product Experience"},
        "Pack_without_product_experience": {color: "teal", label: "Pack without Product Experience"},
        "Pack_with_product_experience": {color: "red", label: "Pack with Product Experience"},
        "Product": {color: "green", label: "Product"},
        "Shopper_and_Shopper_Marketing": {color: "orange", label: "Shopper & Shopper Marketing"},
        "Branding": {color: "pink", label: "Branding"},
        "Other": {color: "green", label: "Other"},
        default: {color: "blue", label: ""}
      },
  },
  getters: {
    dealColorMappings: state => state.dealColorMappings,
  },
  mutations: {},
  actions: {
      
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users/min/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params)
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
    fetchDeals(ctx, queryParams) {
      
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/hubspot/deals/', {
              params: queryParams,
              paramsSerializer: params => transformRequestOptions(params) 
        })
          .then(response => {
            
          store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    fetchDeal(ctx, deal) {
      
      store.commit('app/LOADING', true)
      return new Promise((resolve, reject) => {
        axios
          .get(`/hubspot/deals/${deal.id}/`)
          .then(response => {
            
          store.commit('app/LOADING', false)
            resolve(response)
          })
          .catch(error => {
            store.commit('app/LOADING', false)
            reject(error)
          })
      })
    },
    updateDeal(ctx, deal) {
        store.commit('app/LOADING', true)
        return new Promise((resolve, reject) => {
          axios
            .patch(`/hubspot/deals/${deal.id}/`, deal)
            .then(response => {
              
              store.commit('app/LOADING', false)
              resolve(response)
            })
            .catch(error => {
              store.commit('app/LOADING', false)
              reject(error)
            })
        })
      },
      deleteDeal(ctx, { id }) {
        store.commit('app/LOADING', true)
        return new Promise((resolve, reject) => {
          axios
            .delete(`/hubspot/deals/${id}/`)
            .then(response => {
              
              store.commit('app/LOADING', false)
              resolve(response)
            })
            .catch(error => {
              store.commit('app/LOADING', false)
              reject(error)
            })
        })
      },
      addDeal(ctx, data) {
              
        store.commit('app/LOADING', true)
        return new Promise((resolve, reject) => {
          axios
            .post('/hubspot/deals/', {
                ...data
          })
            .then(response => {
              
              store.commit('app/LOADING', false)
              resolve(response)
            })
            .catch(error => {
              store.commit('app/LOADING', false)
              reject(error)
            })
        })
      },
      fetchOffices(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/jobs/offices/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params),
                useCache: true
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      
      fetchOffices(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/jobs/offices/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params),
                useCache: true
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchCompanies(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/hubspot/companies/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params)
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchContacts(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/hubspot/contacts/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params)
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchProperties(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/hubspot/properties/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params)
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchBusinessInit(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/jobs/business_initiatives/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params),
                useCache: true
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchProjectTypes(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/jobs/project_types/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params),
                useCache: true
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchCurrencies(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axios
            .get('/jobs/currencies/', {
                params: queryParams,
                paramsSerializer: params => transformRequestOptions(params),
                useCache: true
              })
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
  },
}
