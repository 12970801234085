<template>
  <div class="h-full" >
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">{{ dealLocal.id ? 'Update' : 'Add' }} Deal</span>
      <v-tooltip 
        bottom
        v-if="isWon" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            class="ml-2"
            @click="buildProject"
          >
            <v-icon size="22">
              {{ icons.mdiFolderPlusOutline }}
            </v-icon>
          </v-btn>
            
        </template>
        <span>Build Project</span>
      </v-tooltip>
      
      <v-spacer></v-spacer>
      <v-tooltip 
        bottom
        v-if="isWon" >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="dealLocal.id"
            icon
            small
            class="me-1"
                v-bind="attrs"
                v-on="on"
            :loading="loadingDel"
            @click=" $emit('remove-deal', dealLocal); loadingDel=true"
          >
            <v-icon size="22">
              {{ icons.mdiTrashCanOutline }}
            </v-icon>
          </v-btn>
            
        </template>
        <span>Delete Deal</span>
      </v-tooltip>
      
      <v-btn
        icon
        small
        @click="close"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>
      <v-form
        ref="refDealEventHandlerForm"
        class="px-5 pt-3 pb-10"
        @submit.prevent="handleFormSubmit"
        v-if="dealLocal.properties"
      >
        <v-text-field
          v-model="dealLocal.properties.dealname"
          :append-icon="dealLocal.id ? icons.mdiHubspot : undefined"
          label="Name"
          placeholder="Name"
          outlined
          :rules="[validators.required]"
          hide-details="auto"
          dense
          class="mb-4"
          @click:append="goToHubspotDeal"
        ></v-text-field>
        <v-autocomplete
          v-model="dealLocal.properties.dealstage"
          :prepend-inner-icon="icons.mdiStairs"
          :items="stages"
          attach
          item-text="name"
          item-value="id"
          label="Stage"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.required]"
        ></v-autocomplete>
        <v-autocomplete
          v-model="dealLocal.properties.business__initiative"
          :prepend-inner-icon="icons.mdiCardTextOutline"
          :loading="busInitLoading"
          :items="busInitOptions"
          attach
          item-text="name"
          item-value="hs_value"
          label="Business Initiative"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.required]"
        ></v-autocomplete>

        <v-autocomplete
          v-model="dealLocal.properties.house_expertises"
          :prepend-inner-icon="icons.mdiHomeOutline"
          :loading="houseExpertLoading"
          :items="houseExpertOptions"
          attach
          item-text="label"
          item-value="value"
          label="House Expertise"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.sentRequired, validators.negociationRequired, validators.wonRequired]"
        ></v-autocomplete>

        <v-autocomplete
          v-model="dealLocal.properties.surveys_type"
          :prepend-inner-icon="icons.mdiClipboardCheckMultipleOutline "
          :loading="projTypeLoading"
          :items="projTypeOptions"
          attach
          item-text="name"
          item-value="hs_value"
          label="Project Type"
          multiple
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.required]"
        ></v-autocomplete>

        <v-autocomplete
          v-model="dealLocal.properties.deal_currency_code"
          :prepend-inner-icon="icons.mdiCurrencySign"
          :loading="currenciesLoading"
          :items="currenciesOptions"
          attach
          item-text="name"
          item-value="name"
          label="Currencies"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.required]"
        ></v-autocomplete>

        <v-text-field
          v-model.number="dealLocal.properties.amount"
          :prepend-inner-icon="icons.mdiCash"
          label="Amount"
          type="number"
          outlined
          dense
          hide-details="auto"
          class="mb-4"
          :rules="[validators.required]"
        ></v-text-field>
        <v-text-field
          v-model.number="dealLocal.properties.estimated_gross_profit"
          :prepend-inner-icon="icons.mdiCash"
          label="Estimated Gross Profit"
          type="number"
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.grossMarginValidator]"
        ></v-text-field>

        <v-autocomplete
          :prepend-inner-icon="icons.mdiAccountMultiplePlusOutline"
          v-model="dealLocal.properties.hubspot_owner_id"
          :loading="usersLoading"
          :items="HubspotUsersOptions"
          attach
          item-text="name"
          item-value="hubspot_owner_id"
          label="Owner"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.required]"
          :menu-props="{ offsetY: true, contentClass: 'list-style' }"
        >
          <!-- Options Slot -->
          <template #item="{ item }">
            <div class="d-flex align-center">
              <v-avatar
                    :color="stringToHslColor(item.name) || primary"
                    size="25"
                    class="me-2"
                >
                    <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                </v-avatar>
              <span class="text-sm">{{ item.name }}</span>
            </div>
          </template>
        </v-autocomplete>

        <v-menu
          v-model="isCloseDateMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dealLocal.properties.closedate"
              :prepend-inner-icon="icons.mdiCalendarRange"
              label="Close Date"
              outlined
              dense
              class="mb-4"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
              :rules="[validators.wonRequired]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dealLocal.properties.closedate"
            :first-day-of-week="1"
            no-title
            scrollable
            @input="isCloseDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
          v-model="isStartDateMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dealLocal.properties.fieldwork_start_date"
              :prepend-inner-icon="icons.mdiCalendarRangeOutline"
              label="Est. Field Start"
              readonly
              outlined
              dense
              class="mb-4"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
              :rules="[validators.startDateValidator]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dealLocal.properties.fieldwork_start_date"
            :first-day-of-week="1"
            no-title
            scrollable
            @input="isStartDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="isEndDateMenuOpen"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dealLocal.properties.fieldwork_end_date"
              :prepend-inner-icon="icons.mdiCalendarRangeOutline"
              label="Est. Field End"
              readonly
              outlined
              dense
              class="mb-4"
              hide-details="auto"
              v-bind="attrs"
              v-on="on"
              :rules="[validators.endDateValidator]"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dealLocal.properties.fieldwork_end_date"
            :first-day-of-week="1"
            no-title
            scrollable
            @input="isEndDateMenuOpen = false"
          ></v-date-picker>
        </v-menu>
        <v-autocomplete
          v-model="dealLocal.properties.project_geography"
          :prepend-inner-icon="icons.mdiEarth"
          :loading="geographyLoading"
          :items="geographyOptions"
          attach
          item-text="label"
          item-value="value"
          label="Project Geography"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.sentRequired, validators.negociationRequired, validators.wonRequired]"
        ></v-autocomplete>

        <v-autocomplete
          v-model="dealLocal.properties.deal_type__repeat_or_new_biz_"
          :prepend-inner-icon="icons.mdiHandshakeOutline"
          :loading="dealTypeLoading"
          :items="dealTypeOptions"
          attach
          item-text="label"
          item-value="value"
          label="Deal Type"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.sentRequired, validators.negociationRequired, validators.wonRequired]"
        ></v-autocomplete>
        <v-autocomplete
          v-model="dealLocal.properties.support_of_other_bva_group_teams"
          :prepend-inner-icon="icons.mdiFaceAgent"
          :loading="supportGroupLoading"
          :items="supportGroupOptions"
          attach
          item-text="label"
          item-value="value"
          label="Support of other BVA Groups"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.sentRequired, validators.negociationRequired, validators.wonRequired]"
        ></v-autocomplete>
        <v-autocomplete
          v-model="dealLocal.properties.survey_collection_mode"
          :prepend-inner-icon="icons.mdiImageMultiple"
          :loading="collectModeLoading"
          :items="collectModeOptions"
          attach
          item-text="label"
          item-value="value"
          label="Survey Collection Mode"
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          :rules="[validators.sentRequired, validators.negociationRequired, validators.wonRequired]"
        ></v-autocomplete>

        <v-autocomplete
          v-model="dealLocal.properties.closed_lost_reason"
          :loading="lostReasonLoading"
          :items="lostReasonOptions"
          attach
          item-text="label"
          item-value="value"
          label="Reason Lost"
          outlined
          small-chips
          deletable-chips
          required
          dense
          hide-details="auto"
          class="mb-4"
          :rules="[validators.lostRequired]"
          v-if="isLost"
        ></v-autocomplete>

        <v-autocomplete
          :prepend-inner-icon="icons.mdiAccountMultiplePlusOutline"
          v-model="dealLocal.associations.contacts.results"
          :loading="contactsAssocationLoading"
          attach
          :search-input.sync="contactsAssocationSearch"
          :items="contactsAssocationOptions"
          item-text="properties.email"
          item-value="id"
          label="Contact"
          multiple
          small-chips
          deletable-chips
          required
          dense
          outlined
          hide-details="auto"
          class="mb-4"
          @change="contactsAssocationSearch=''"
          :rules="[validators.requiredArray]"
      >
          <template v-slot:selection="data">
              <v-chip
              small
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeContacts(data.item)"
              >
              {{ data.item.properties.firstname }} {{ data.item.properties.lastname }}
              </v-chip>
          </template>
          <template v-slot:item="data">
            <v-list-item-content>
                <v-list-item-title >{{ data.item.properties.firstname }} {{ data.item.properties.lastname }}</v-list-item-title>
                <v-list-item-subtitle class="mb-2">{{data.item.properties.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
          
          <template v-slot:append-outer>
              
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      
                  <v-icon
                      key="iconPlusContact"
                      @click="goToHubspotContacts"
                      v-bind="attrs"
                      v-on="on">
                      {{icons.mdiPlus}}
                  </v-icon>
                      
                  </template>
                  <span>Contact Creation</span>
              </v-tooltip>
              
          </template>
      </v-autocomplete>

      <v-autocomplete
        :prepend-inner-icon="icons.mdiDomain"
        v-model="dealLocal.associations.companies.results"
        :loading="companiesAssocationLoading"
        attach
        :search-input.sync="companiesAssocationSearch"
        :items="companiesAssocationOptions"
        item-text="properties.name"
        item-value="id"
        label="Company"
        multiple
        small-chips
        deletable-chips
        required
        dense
        outlined
        hide-details="auto"
        class="mb-4"
        @change="companiesAssocationSearch=''"
        :rules="[validators.requiredArray]"
    >
        <template v-slot:append-outer>
              
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      
                  <v-icon
                      key="iconPlusCompanies"
                      @click="goToHubspotCompanies"
                      v-bind="attrs"
                      v-on="on">
                      {{icons.mdiPlus}}
                  </v-icon>
                      
                  </template>
                  <span>Company Creation</span>
              </v-tooltip>
              
          </template>
    </v-autocomplete>
        
        <v-btn
          color="primary"
          class="me-3"
          type="submit"
        >
          {{ dealLocal.id ? 'Update' : 'Add ' }}
        </v-btn>
        <v-btn
          outlined
          @click="resetDealLocal"
        >
          Reset
        </v-btn>
      </v-form>
  </div>
</template>

<script>
import { ref, watch, computed, onMounted } from '@vue/composition-api'

import { required, requiredArray } from '@core/utils/validation'
import { dateInPast, stringToHslColor, defaultProject } from '@core/utils'
import { avatarText } from '@core/utils/filter'
import { mdiTrashCanOutline, mdiClose, mdiStairs, mdiCurrencySign,
          mdiCash, mdiAccountMultiplePlusOutline, mdiCalendarRange, mdiHandshakeOutline,
          mdiPlus, mdiDomain, mdiHubspot, mdiHomeOutline, mdiClipboardCheckMultipleOutline,
          mdiEarth, mdiCalendarRangeOutline, mdiCardTextOutline,
          mdiImageMultiple, mdiFaceAgent, mdiFolderPlusOutline } from '@mdi/js'
import useHubspot from './useHubspotList'

export default {
  props: {
    deal: {
      type: Object,
      required: true,
    },
    clearDealData: {
      type: Function,
      required: true,
    },
    stages: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    // ————————————————————————————————————
    //* ——— Template Refs
    // ————————————————————————————————————

    const refDealEventHandlerForm = ref(null)
    
    const loadingAdd = ref(false)
    const loadingDel = ref(false)

    // ————————————————————————————————————
    //* ——— Local Event
    // ————————————————————————————————————

    const dealLocal = ref(JSON.parse(JSON.stringify(props.deal)))

    const {

        usersLoading,
        usersOptions,
        usersSearch,
        houseExpertLoading,
        houseExpertOptions,
        geographyLoading,
        geographyOptions,
        dealTypeLoading,
        dealTypeOptions,
        supportGroupLoading,
        supportGroupOptions,
        collectModeLoading,
        collectModeOptions,
        lostReasonLoading,
        lostReasonOptions,
        busInitLoading,
        busInitOptions,
        projTypeLoading,
        projTypeOptions,
        currenciesLoading,
        currenciesOptions,
        contactsAssocationLoading,
        contactsAssocationOptions,
        contactsAssocationSearch,
        
        companiesAssocationLoading,
        companiesAssocationOptions,
        companiesAssocationSearch,

        fetchHouseExpert,
        fetchGeography,
        fetchDealType,
        fetchSupportGroup,
        fetchLostReason,
        fetchCurrencies,
        fetchProjectTypes,
        fetchBusinessInit,
        fetchUsers,
        fetchCollectMode,
        fetchContactsAssociation,
        fetchCompaniesAssociation,

    } = useHubspot()

    const resetDealLocal = () => {
      loadingAdd.value = false
      loadingDel.value = false
      dealLocal.value = JSON.parse(JSON.stringify(props.deal))

      // Only get date from event
      if (dealLocal.value.properties.closedate) dealLocal.value.properties.closedate = new Date(dealLocal.value.properties.closedate).toISOString().substr(0, 10)
      if (dealLocal.value.properties.createdate) dealLocal.value.properties.createdate = new Date(dealLocal.value.properties.createdate).toISOString().substr(0, 10)
      if (dealLocal.value.properties.fieldwork_start_date) dealLocal.value.properties.fieldwork_start_date = new Date(dealLocal.value.properties.fieldwork_start_date).toISOString().substr(0, 10)
      if (dealLocal.value.properties.fieldwork_end_date) dealLocal.value.properties.fieldwork_end_date = new Date(dealLocal.value.properties.fieldwork_end_date).toISOString().substr(0, 10)
      //if (dealLocal.value.properties.hs_all_assigned_business_unit_ids) dealLocal.value.properties.hs_all_assigned_business_unit_ids = dealLocal.value.properties.hs_all_assigned_business_unit_ids.split(";")
      if (dealLocal.value.properties.surveys_type) dealLocal.value.properties.surveys_type = dealLocal.value.properties.surveys_type.split(";")
      // if (!dealLocal.value.hubspot_owner_id) dealLocal.value.properties.hubspot_owner_id = this.$store.state.user.hubspot_owner_id,

      dealLocal.value.associations.contacts.results = dealLocal.value.associations.contacts.results.map(item => item.id)
      dealLocal.value.associations.companies.results = dealLocal.value.associations.companies.results.map(item => item.id)
      fetchContactsAssociation({contactsIds:dealLocal.value.associations.contacts.results})
      fetchCompaniesAssociation({companiesIds:dealLocal.value.associations.companies.results})

      if (dealLocal.value.id) {
        refDealEventHandlerForm.value.validate()
      } else {
        // Reset Validation
        refDealEventHandlerForm.value.resetValidation()
      }
    }

    // ————————————————————————————————————
    //* ——— Form
    // ————————————————————————————————————

    const isCloseDateMenuOpen = ref(false)
    const isStartDateMenuOpen = ref(false)
    const isEndDateMenuOpen = ref(false)



    const HubspotUsersOptions = computed(() => {
        return usersOptions.value.filter( user => user.hubspot_owner_id !== '')
    })

    const goToHubspotDeal = () => {
      window.open(`https://app.hubspot.com/contacts/${process.env.VUE_APP_HUBSPOT_ID}/deal/${dealLocal.value.id}/`, '_blank')
    }
    const goToHubspotContacts = () => {
      window.open(`https://app.hubspot.com/contacts/${process.env.VUE_APP_HUBSPOT_ID}/objects/0-1/views/all/list`, '_blank')
    }
    const goToHubspotCompanies = () => {
      window.open(`https://app.hubspot.com/contacts/${process.env.VUE_APP_HUBSPOT_ID}/objects/0-2/views/all/list`, '_blank')
    }

    const removeContacts = (item) => {

      const index = dealLocal.value.associations.contacts.results.indexOf(item.id)
      if (index >= 0) dealLocal.value.associations.contacts.results.splice(index, 1)
    }

    const handleFormSubmit = () => {
      const isFormValid = refDealEventHandlerForm.value.validate()
      if (!isFormValid) return

      const dealData = JSON.parse(JSON.stringify(dealLocal.value))

      // * If event has id => Edit Event
      // Emit event for add/update event
      if (dealData.id) emit('update-deal', dealData, props.deal.properties.dealstage)
      else emit('add-deal', dealData)

    }

    const buildProject = () => {
      const isFormValid = refDealEventHandlerForm.value.validate()
      if (!isFormValid) return
      const dealData = JSON.parse(JSON.stringify(dealLocal.value))

      const project = {
        ...defaultProject,
        job_name:dealData.properties.dealname.substr(0,42),
        hs_deal:dealData.id,
        closed_won_date: dealData.properties.closedate,
        budget: dealData.properties.surveys_type.length == 1 ? dealData.properties.amount : null,
        currency: currenciesOptions.value.find(element => element.name === dealData.properties.deal_currency_code).id,
        project_type: dealData.properties.surveys_type.length == 1 ? projTypeOptions.value.find(element => element.hs_value === dealData.properties.surveys_type[0]).id : null,
        // office: this.office.id,
        business_initiative: busInitOptions.value.find(element => element.hs_value === dealData.properties.business__initiative).id,
        estimated_gross_margin: dealData.properties.estimated_gross_profit
      }
      emit('create-project', project)
  }

    // ————————————————————————————————————
    //* ——— Form Validator
    // ————————————————————————————————————

    

    const dealStage = computed(() => {
      return props.stages.find(stage => stage.id === dealLocal.value.properties.dealstage)
    })

    const isLost = computed(() => {
      return dealLocal.value.properties.dealstage && dealStage.value.name === 'Closed Lost'
    })

    const isWon = computed(() => {
      return dealLocal.value.properties.dealstage && dealStage.value.name === 'Closed Won'
    })

    const isIdentified = computed(() => {
      return dealLocal.value.properties.dealstage && dealStage.value.name === 'Sales opportunity identified (1%)'
    })

    const isSelected = computed(() => {
      return dealLocal.value.properties.dealstage && dealStage.value.name === 'Sales opportunity selected (1%)'
    })

    const isSent = computed(() => {
      return dealLocal.value.properties.dealstage && dealStage.value.name === 'Proposal sent (60%)'
    })

    const isNegociation = computed(() => {
      return dealLocal.value.properties.dealstage && dealStage.value.name === 'Proposal in negociation (80%)'
    })

    const sentRequired = (value) => {
      if (isSent.value) return required(value)
      return true
    }

    const negociationRequired = (value) => {
      if (isNegociation.value) return required(value)
      return true
    }

    const wonRequired = (value) => {
      if (isWon.value) return required(value)
      return true
    }

    const lostRequired = (value) => {
      if (isLost.value) return required(value)
      return true
    }

    const grossMarginValidator = val => {
      if (val < 0 ) return 'Gross Margin cannot be negative'
      if (val > dealLocal.value.properties.amount) return 'Gross Margin cannot be higher than deal amount'
      return true
    }

    const endDateValidator = val => {
      if (!dealLocal.value.properties.fieldwork_start_date) return true
      return !dateInPast(new Date(val), new Date(dealLocal.value.properties.fieldwork_start_date)) || 'End date cannot be before Start date'
    }

    const startDateValidator = val => {
      if (!dealLocal.value.properties.closedate) return true
      if (dealLocal.value.properties.fieldwork_start_date) return !dateInPast(new Date(val), new Date(dealLocal.value.properties.closedate)) || 'Start date cannot be before Close date'
      return true
    }

    const close = () => {
      emit('close-drawer', dealLocal.value.properties.prevstage)
    }

    watch(
      contactsAssocationSearch,
      () => {
        if (contactsAssocationSearch.value && contactsAssocationSearch.value.length >= 2){
          fetchContactsAssociation({search:contactsAssocationSearch.value})
        }
      }
    )

    watch(
      companiesAssocationSearch,
      () => {
        if (companiesAssocationSearch.value && companiesAssocationSearch.value.length >= 2){
          fetchCompaniesAssociation({search:companiesAssocationSearch.value})
        }
      }
    )
    
    watch(
      () => props.deal,
      () => {
        resetDealLocal()
      },
      { deep: true },
    )

    onMounted(() => {
      fetchHouseExpert()
      fetchGeography()
      fetchDealType()
      fetchSupportGroup()
      fetchLostReason()
      fetchCurrencies()
      fetchProjectTypes()
      fetchCollectMode()
      fetchBusinessInit()
      fetchUsers()
    })

    return {
      // Template Refs
      refDealEventHandlerForm,

      // Local Event
      dealLocal,
      resetDealLocal,
      dealStage,
      isLost,
      isWon,
      goToHubspotDeal,
      goToHubspotContacts,
      goToHubspotCompanies,
      removeContacts,
      close,
      buildProject,

      // Form
      isCloseDateMenuOpen,
      isStartDateMenuOpen,
      isEndDateMenuOpen,
      handleFormSubmit,

      loadingAdd,
      loadingDel,

      stringToHslColor,
      avatarText,
      
      usersLoading,
      HubspotUsersOptions,
      usersSearch,
      houseExpertLoading,
      houseExpertOptions,
      geographyLoading,
      geographyOptions,
      dealTypeLoading,
      dealTypeOptions,
      supportGroupLoading,
      supportGroupOptions,
      collectModeLoading,
      collectModeOptions,
      lostReasonLoading,
      lostReasonOptions,
      busInitLoading,
      busInitOptions,
      projTypeLoading,
      projTypeOptions,
      currenciesLoading,
      currenciesOptions,
      contactsAssocationLoading,
      contactsAssocationOptions,
      contactsAssocationSearch,
      companiesAssocationLoading,
      companiesAssocationOptions,
      companiesAssocationSearch,

      // Field Validators
      validators: {
        required,
        requiredArray,
        sentRequired,
        negociationRequired,
        wonRequired,
        lostRequired,
        endDateValidator,
        startDateValidator,
        grossMarginValidator
      },

      // Icons
      icons: {
        mdiTrashCanOutline,
        mdiClose,
        mdiCash,
        mdiAccountMultiplePlusOutline,
        mdiPlus,
        mdiDomain,
        mdiHubspot,
        mdiClipboardCheckMultipleOutline,
        mdiHandshakeOutline,
        mdiHomeOutline,
        mdiStairs,
        mdiCurrencySign,
        mdiCalendarRange,
        mdiFaceAgent,
        mdiEarth,
        mdiCalendarRangeOutline,
        mdiCardTextOutline,
        mdiImageMultiple,
        mdiFolderPlusOutline
      },
    }
  },
}
</script>

<style lang="scss">

.ps-calendar-event-handler {
  height: calc(100% - 44px - 24px - 2px);

  // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
  // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
}

  .v-menu__content:not(.list-style) .v-list.v-list--dense .v-list-item {
    min-height: 3rem !important;
  }
</style>
