import store from '@/store'

import { ref, getCurrentInstance, watch, computed } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';
import { stringToHslColor, getInitialName } from '@core/utils'



export default function useHubspotList() {

    const vm = getCurrentInstance().proxy

    const deals = ref([])
    const loading = ref(false)

    
    const usersLoading = ref(false)
    const usersOptions = ref([])
    const usersSearch = ref('')

    const officeLoading = ref(false)
    const officeOptions = ref([])
    const officeSearch = ref('')

    const ownerSearch = ref('')

    const stagesLoading = ref(false)
    const stagesOptions = ref([])

    
    const houseExpertLoading = ref(false)
    const houseExpertOptions = ref([])
    
    const geographyLoading = ref(false)
    const geographyOptions = ref([])
    
    const dealTypeLoading = ref(false)
    const dealTypeOptions = ref([])
    
    const supportGroupLoading = ref(false)
    const supportGroupOptions = ref([])
    
    const collectModeLoading = ref(false)
    const collectModeOptions = ref([])
    
    const lostReasonLoading = ref(false)
    const lostReasonOptions = ref([])
    
    const busInitLoading = ref(false)
    const busInitOptions = ref([])
    
    const projTypeLoading = ref(false)
    const projTypeOptions = ref([])
    
    const currenciesLoading = ref(false)
    const currenciesOptions = ref([])
    
    const contactsAssocationLoading = ref(false)
    const contactsAssocationOptions = ref([])
    const contactsAssocationSearch = ref('')

    const companiesAssocationLoading = ref(false)
    const companiesAssocationOptions = ref([])
    const companiesAssocationSearch = ref('')

    const stages = ref([])
    const office = ref({})
    const user = ref({})
    const ownerFilter = ref([])
    
    user.value = JSON.parse(localStorage.getItem('user'))
    office.value = user.value.office

    const fetchUsers = async () => {
        usersLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchUsers', {
        })
        .then(response => {
            const { data } = response
            usersOptions.value = data
            // remove loading state
            usersLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchDeals = async () => {
        loading.value = true
        const hubspot_owner_id = JSON.parse(JSON.stringify(ownerFilter.value))
        
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        store
        .dispatch('app-hubspot/fetchDeals', {
            office: office.value,
            hubspot_owner_id
        })
        .then(response => {
            const { data } = response
            deals.value = convertHubToDealPipeObj(data)
            stages.value = data.reduce((dict, el, index) => (dict[el.id] = el, dict), {})
            setStages(deals.value)
            // remove loading state
            loading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }


    const setStages = (stages) => {

        stagesOptions.value = stages.map( (stage) => {
            return {
                id: stage.id,
                name: stage.label
            }
        })
    }

    const removeDealFromStage = (dealIdx, stage) => {
        stages.value[stage].total -= 1;
        stages.value[stage].deals.splice(dealIdx, 1);
        if (stages.value[stage].paging){
          
          const after = parseInt(stages.value[stage].paging.next.after)
          stages.value[stage].paging.next.after = after-1;
        }
      }
      const addDealFromStage = (deal, stage, oldIndex=null) => {
        deal.user = getUserDetail(deal.properties.hubspot_owner_id)
        if (oldIndex) {
            stages.value[stage].deals.splice(oldIndex, 0, deal)
        } else {
            stages.value[stage].deals.unshift(deal)
        }
        stages.value[stage].total += 1;
        if (stages.value[stage].paging){
          const after = parseInt(stages.value[stage].paging.next.after)
          stages.value[stage].paging.next.after = after+1;
        }
      }

    const fetchOffices = async () => {
        officeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchOffices', {
            hubspot:1
        })
        .then(response => {
            const { data } = response
            officeOptions.value = data

            // remove loading state
            officeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    const fetchCurrencies = async () => {
        currenciesLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchCurrencies', {
            hubspot:1
        })
        .then(response => {
            const { data } = response
            currenciesOptions.value = data

            // remove loading state
            currenciesLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    const fetchProjectTypes = async () => {
        projTypeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchProjectTypes', {
            hubspot:1
        })
        .then(response => {
            const { data } = response
            projTypeOptions.value = data
            // remove loading state
            projTypeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }
    const fetchBusinessInit = async () => {
        busInitLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchBusinessInit', {
            hubspot:1
        })
        .then(response => {
            const { data } = response
            busInitOptions.value = data

            // remove loading state
            busInitLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchHouseExpert = async () => {
        houseExpertLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchProperties', {
            object_type:'deals',
            property_name:'house_expertises'
        })
        .then(response => {
            const { options } = response.data[0] || response.data
            houseExpertOptions.value = options
            // remove loading state
            houseExpertLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchGeography = async () => {
        geographyLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchProperties', {
            object_type:'deals',
            property_name:'project_geography'
        })
        .then(response => {
            const { options } = response.data[0] || response.data
            geographyOptions.value = options
            // remove loading state
            geographyLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })   
    }

    const fetchDealType = async () => {
        dealTypeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchProperties', {
            object_type:'deals',
            property_name:'deal_type__repeat_or_new_biz_'
        })
        .then(response => {
            const { options } = response.data[0] || response.data
            dealTypeOptions.value = options
            // remove loading state
            dealTypeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchSupportGroup = async () => {
        supportGroupLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchProperties', {
            object_type:'deals',
            property_name:'support_of_other_bva_group_teams'
        })
        .then(response => {
            const { options } = response.data[0] || response.data
            supportGroupOptions.value = options
            // remove loading state
            supportGroupLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchCollectMode = async () => {
        collectModeLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchProperties', {
            object_type:'deals',
            property_name:'survey_collection_mode'
        })
        .then(response => {
            const { options } = response.data[0] || response.data
            collectModeOptions.value = options
            // remove loading state
            collectModeLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchLostReason = async () => {
        lostReasonLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        
        store
        .dispatch('app-hubspot/fetchProperties', {
            object_type:'deals',
            property_name:'closed_lost_reason'
        })
        .then(response => {
            const { options } = response.data[0] || response.data
            lostReasonOptions.value = options
            // remove loading state
            lostReasonLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    
    const dealColorMappings = computed( () =>{
        return store.getters['app-hubspot/dealColorMappings']
    })

    const color = (deal) => {
      if (deal.properties.business__initiative) return dealColorMappings.value[deal.properties.business__initiative].color || dealColorMappings.value.default.color
      return dealColorMappings.value.default.color
    }
    
    const convertHubToDealObj = (deal) => {
        const localDeal = JSON.parse(JSON.stringify(deal))
        //fieldwork date isn't in midnight GMT timelight from Hubspot API so need to reset
        if (localDeal.properties.fieldwork_start_date) {
            localDeal.properties.fieldwork_start_date = new Date(localDeal.properties.fieldwork_start_date).toISOString()
        }
        if (localDeal.properties.fieldwork_end_date) {
            localDeal.properties.fieldwork_end_date = new Date(localDeal.properties.fieldwork_end_date).toISOString()
        }
        localDeal.user = getUserDetail(localDeal.properties.hubspot_owner_id)
        localDeal.color = color(localDeal)

        return localDeal

    }
    const convertHubToDealPipeObj = (pipeline) => {
        for (const i in pipeline) {
          const stage = pipeline[i]
          pipeline[i].deals = stage.deals.map(deal => convertHubToDealObj(deal))
        }
        return pipeline
    }

    const convertDealObjToHub = (deal) => {
        const sanitizedDeal =  {
            id:deal.id,
            createdAt:deal.createdAt,
            updatedAt:deal.updatedAt,
            archived:deal.archived,
            properties: {
                ...deal.properties,
                closedate: deal.properties.closedate ? new Date(deal.properties.closedate).toISOString() : null,
                createdate: deal.properties.createdate ? new Date(deal.properties.createdate).toISOString() : null,
                fieldwork_start_date: deal.properties.fieldwork_start_date ? new Date(deal.properties.fieldwork_start_date).toISOString() : null,
                fieldwork_end_date: deal.properties.fieldwork_end_date ? new Date(deal.properties.fieldwork_end_date).toISOString() : null,
                // hs_all_assigned_business_unit_ids:deal.properties.hs_all_assigned_business_unit_ids ? deal.properties.hs_all_assigned_business_unit_ids : null,
                surveys_type:deal.properties.surveys_type.join(";"),
                hubspot_owner_id:deal.properties.hubspot_owner_id ? deal.properties.hubspot_owner_id : this.$store.state.user.hubspot_owner_id,
            },
            associations: {
                companies:{
                    results: []
                },
                contacts:{
                    results: []
                }
            }
        }

        const companies = [...new Set(deal.associations.companies.results)]
        for (var i in companies){
            sanitizedDeal.associations.companies.results.push(
                {
                    id: companies[i].id ? companies[i].id : companies[i],
                    type: "deal_to_company"
                }
            )
        }

        
        const contacts = [...new Set(deal.associations.contacts.results)]
        for (var i in contacts){
            sanitizedDeal.associations.contacts.results.push(
                {
                    id: contacts[i].id ? contacts[i].id : contacts[i],
                    type: "deal_to_contact"
                }
            )
        }
        return sanitizedDeal
    }

    const getUserDetail = (userId) => {
      if (userId){
        var user = usersOptions.value.find(element => element.hubspot_owner_id === userId)
        if (user) {

          user.initial = getInitialName(user.name);
          user.color = stringToHslColor(user.name)
          return user
        }
        return null
      }
      return null
    }

    const fetchContactsAssociation = async ({contactsIds=[], search=''}={}) => {
        contactsAssocationLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        const params = ref({})
        if (search) params.value.query = search
        if (contactsIds) params.value.hs_object_id = contactsIds
        
        store
        .dispatch('app-hubspot/fetchContacts', params.value)
        .then(response => {
            const { results } = response.data[0] || response.data
            const contacts = [
                ...contactsAssocationOptions.value,
                ...results
            ]
            // remove duplicated contact obj from array
            contactsAssocationOptions.value = [...contacts.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()]
            contactsAssocationLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    const fetchCompaniesAssociation = async ({companiesIds=[], search=''}={}) => {
        companiesAssocationLoading.value = true
        const token = await vm.$msal.getTokenPopup(foundryRequest)

        const params = ref({})
        if (search) params.value.query = search
        if (companiesIds) params.value.hs_object_id = companiesIds
        store
        .dispatch('app-hubspot/fetchCompanies', params.value)
        .then(response => {
            const { results } = response.data[0] || response.data
            const companies = [
                ...companiesAssocationOptions.value,
                ...results
            ]
            // remove duplicated company obj from array
            companiesAssocationOptions.value = [...companies.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()]
            // remove loading state
            companiesAssocationLoading.value = false
        })
        .catch(error => {
            console.log(error)
        })
    }

    
    const loadFiltersFromLS = () => {
        if(localStorage.getItem('hubspot-filter')){
        const { owners } = JSON.parse(localStorage.getItem('hubspot-filter'))
        ownerFilter.value = owners || ''
        }

    }
    const updateFilter = (attr, obj) => {
        let filter = JSON.parse(localStorage.getItem('hubspot-filter'))
        filter = filter ? filter : {}
          filter[attr] = []
        
          obj.forEach(item => { 
            if (item.value){
              filter[attr].push(item.value)
            } else {
              filter[attr].push(item)
            }
          });
    
          localStorage.setItem('hubspot-filter', JSON.stringify(filter));
      }


    watch([office, ownerFilter], () => {
        fetchDeals()
    })
    

    return {
        fetchDeals,
        fetchOffices,

        officeLoading,
        officeOptions,
        officeSearch,
        office,
        stages,
        ownerFilter,
        ownerSearch,

        usersLoading,
        usersOptions,
        usersSearch,

        stagesLoading,
        stagesOptions,
        houseExpertLoading,
        houseExpertOptions,
        geographyLoading,
        geographyOptions,
        dealTypeLoading,
        dealTypeOptions,
        supportGroupLoading,
        supportGroupOptions,
        collectModeLoading,
        collectModeOptions,
        lostReasonLoading,
        lostReasonOptions,
        busInitLoading,
        busInitOptions,
        projTypeLoading,
        projTypeOptions,
        currenciesLoading,
        currenciesOptions,
        contactsAssocationLoading,
        contactsAssocationOptions,
        contactsAssocationSearch,
        companiesAssocationLoading,
        companiesAssocationOptions,
        companiesAssocationSearch,

        fetchHouseExpert,
        fetchGeography,
        fetchDealType,
        fetchSupportGroup,
        fetchLostReason,
        fetchCurrencies,
        fetchProjectTypes,
        fetchBusinessInit,
        fetchCollectMode,
        fetchUsers,
        fetchContactsAssociation,
        fetchCompaniesAssociation,
        loadFiltersFromLS,
        updateFilter,

        convertDealObjToHub,
        convertHubToDealObj,
        removeDealFromStage,
        addDealFromStage,
        getUserDetail,
    }
}